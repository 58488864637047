<template>
  <div class="container">
    <div class="avia-confirmation" v-if="item && Object.keys(item).length > 0">

      <div class="left">
        <div class="title">Бронирование билета</div>
        <div class="direction">
          <p>{{ item.segments[0].dep.airport.title }} ({{ item.segments[0].dep.airport.code }})</p>
          <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 8.5H21M21 8.5L18 5.5M21 8.5L18 11.5" stroke="black" stroke-width="1.8"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M21 15.5H3M3 15.5L6 12.5M3 15.5L6 18.5" stroke="black" stroke-width="1.8"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                    </span>
          <p> {{ item.segments[0].arr.airport.title }} ({{ item.segments[0].arr.airport.code }})</p>
        </div>
        <div class="date-and-persons">
          {{ getDate }} {{ getPassengers }}
        </div>

        <div class="panel">
          <div class="title">Детали маршрута</div>
          <div class="title-help">Время отправления и прибытия местное</div>

          <div class="ticket">

            <div class="logo">
              <template v-if="item.segments.length <= 1">
                <span>{{ item.segments[0].provider.supplier.title }}</span>
              </template>

              <template v-else-if="item.segments.length >= 2">
                <template
                    v-if="item.segments[0].provider.supplier.id == item.segments[1].provider.supplier.id">
                  <span>{{ item.segments[0].provider.supplier.title }}</span>
                </template>
                <template v-else>
                                    <span>{{
                                        item.segments[0].provider.supplier.title
                                      }} {{ item.segments[1].provider.supplier.title }}</span>
                </template>
              </template>
            </div>

            <div class="timing">
              <div class="item" v-for="(segment, index) in item.segments" :key="index">

                <div class="there" v-if="segment.direction === 0">
                  <div class="from">
                    <div class="datetime">
                      <time>{{ segment.dep.time }}</time>
                      <span>{{
                          $moment.unix(segment.dep.ts).format('DD MMM')
                        }}</span>
                    </div>
                    <div class="airport">
                      {{ segment.dep.airport.title }}
                      ({{ segment.dep.airport.code }})
                    </div>
                  </div>
                  <div class="in-flight" v-if="$store.getters.width > 576">
                    <div class="line-flight"></div>
                    <span>{{ segment.duration.flight.hour }}ч {{
                        segment.duration.flight.minute
                      }} мин</span>
                    <div class="line-flight"></div>
                  </div>
                  <div class="to">
                    <div class="datetime">
                      <time>{{ segment.arr.time }}</time>
                      <span>{{
                          $moment.unix(segment.arr.ts).format('DD MMM')
                        }}</span>
                    </div>
                    <div class="airport">
                      {{ segment.arr.airport.title }}
                      ({{ segment.arr.airport.code }})
                    </div>
                  </div>
                </div>

                <div class="back" v-else>
                  <div class="from">
                    <div class="datetime">
                      <time>{{ segment.dep.time }}</time>
                      <span>{{
                          $moment.unix(segment.dep.ts).format('DD MMM')
                        }}</span>
                    </div>
                    <div class="airport">
                      {{ segment.dep.airport.title }}
                      ({{ segment.dep.airport.code }})
                    </div>
                  </div>
                  <div class="in-flight">
                    <div class="line-flight"></div>
                    <span>{{ segment.duration.flight.hour }}ч {{
                        segment.duration.flight.minute
                      }} мин</span>
                    <div class="line-flight"></div>
                  </div>
                  <div class="to">
                    <div class="datetime">
                      <time>{{ segment.arr.time }}</time>
                      <span>{{
                          $moment.unix(segment.arr.ts).format('DD MMM')
                        }}</span>
                    </div>
                    <div class="airport">
                      {{ segment.arr.airport.title }}
                      ({{ segment.arr.airport.code }})
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>

        <div class="panel" v-if="this.tariffs.length > 0">
          <div class="title">Выберите тариф</div>
          <p><b>Перелёт туда:</b> {{ item.segments[0].dep.airport.title }} -
            {{ item.segments[0].arr.airport.title }}</p>
          <p>{{ item.segments[0].provider.supplier.title }}</p>

          <div class="tariffs">
            <div class="tariff"
                 v-for="(tariff, index) in tariffs" :key="tariff.id"
                 :class="[selectedTariff.id === tariff.id ? 'active':'']">
              <div class="name">Тариф #{{ index + 1 }}</div>
              <div class="price">{{ tariff.amount.toLocaleString('ru-RU') }} руб.</div>
              <ul class="additions">
                <li :class="[tariff.segments[0].cbaggage.piece === 1 ? 'mark' : 'cross']">
                  Ручная кладь
                  <template v-if="tariff.segments[0].cbaggage.weight">
                    {{ tariff.segments[0].cbaggage.weight }} кг
                  </template>
                  ,

                  <template v-if="tariff.segments[0].cbaggage.dimensions">
                    {{
                      tariff.segments[0].cbaggage.dimensions.width + 'x' + tariff.segments[0].cbaggage.dimensions.length + 'x' + tariff.segments[0].cbaggage.dimensions.height + 'см'
                    }}
                  </template>
                </li>
                <li :class="[tariff.segments[0].baggage.piece > 0 ? 'mark' : 'cross']">
                  Багаж
                  <template
                      v-if="tariff.segments[0].baggage.piece > 0 && tariff.segments[0].baggage.weight">
                    {{ tariff.segments[0].baggage.weight }}кг
                  </template>
                </li>
                <li :class="[tariff.segments[0].change ? 'mark' : 'cross']">
                  Обмен
                </li>
                <li :class="[tariff.segments[0].refund ? 'mark' : 'cross']">
                  Возврат
                </li>
              </ul>
              <template v-if="selectedTariff.id === tariff.id">
                <button class="btn disabled">Выбрано</button>
              </template>
              <template v-else>
                <button class="btn" @click="setTariff(tariff)">Выбрать</button>
              </template>
            </div>
          </div>

          <hr>

          <template v-if="data.returnDate">
            <p class="mt-2"><b>Перелёт обратно:</b> {{ item.segments[1].dep.airport.title }} -
              {{ item.segments[1].arr.airport.title }}</p>
            <p>{{ item.segments[1].provider.supplier.title }}</p>

            <div class="tariffs">
              <div class="tariff"
                   v-for="(tariff, index) in tariffs" :key="tariff.id"
                   :class="[selectedTariff.id === tariff.id ? 'active':'']">
                <div class="name">Тариф #{{ index + 1 }}</div>
                <div class="price">{{ tariff.amount.toLocaleString('ru-RU') }} руб.</div>
                <ul class="additions">
                  <li :class="[tariff.segments[1].cbaggage.piece === 1 ? 'mark' : 'cross']">
                    Ручная кладь
                    <template v-if="tariff.segments[1].cbaggage.weight">
                      {{ tariff.segments[1].cbaggage.weight }} кг
                    </template>
                    ,
                    <template v-if="tariff.segments[1].cbaggage.dimensions"> {{
                        tariff.segments[1].cbaggage.dimensions.width + 'x' + tariff.segments[1].cbaggage.dimensions.length + 'x' + tariff.segments[1].cbaggage.dimensions.height + 'см'
                      }}
                    </template>
                  </li>
                  <li :class="[tariff.segments[1].baggage.piece > 0 ? 'mark' : 'cross']">
                    Багаж
                    <template
                        v-if="tariff.segments[1].baggage.piece > 0 && tariff.segments[1].baggage.weight">
                      {{ tariff.segments[1].baggage.weight }}кг
                    </template>
                  </li>
                  <li :class="[tariff.segments[1].change ? 'mark' : 'cross']">
                    Обмен
                  </li>
                  <li :class="[tariff.segments[1].refund ? 'mark' : 'cross']">
                    Возврат
                  </li>
                </ul>
                <template v-if="selectedTariff.id === tariff.id">
                  <button class="btn disabled">Выбрано</button>
                </template>
                <template v-else>
                  <button class="btn" @click="setTariff(tariff)">Выбрать</button>
                </template>
              </div>
            </div>
          </template>


        </div>

        <div class="panel">
          <div class="passengers">
            <div class="title">Данные пассажира</div>
            <div class="title-help">Если есть загранпаспорт, укажите имя как в нём. В аэропорту сверяют
              данные
            </div>

            <template v-if="this.adults_.length > 0">
              <div class="passenger" v-for="(adult, index) in adults_" :key="adult.id">
                <div class="type">Взрослый</div>

                <div class="block">
                  <div class="form-group">
                    <label class="label">Фамилия</label>
                    <input
                        type="text" placeholder="Фамилия на латинице"
                        v-model="adults_[index].last_name"
                        :class="validate[index].last_name ? 'validate': ''"
                        v-transliteration/>
                  </div>
                  <div class="form-group">
                    <label class="label">Имя</label>
                    <input
                        type="text"
                        placeholder="Имя на латинице"
                        v-model="adults_[index].first_name"
                        :class="validate[index].first_name ? 'validate': ''"
                        v-transliteration/>
                  </div>
                  <div class="form-group">
                    <label class="label">Отчество</label>
                    <input
                        type="text"
                        placeholder="Отчество на латинице"
                        v-model="adults_[index].middle_name"
                        :class="validate[index].middle_name ? 'validate': ''"
                        v-transliteration/>
                    <div class="checkbox-custom">
                      <input
                          type="checkbox"
                          v-model="adults_[index].notLastName">
                      <label>Нет отчества</label>
                    </div>
                  </div>
                </div>

                <div class="block">
                  <div class="form-group birthday">
                    <label class="label">Дата рождения</label>
                    <date-picker
                        class="default"
                        v-model="adults_[index].birth_date"
                        placeholder="Дата рождения"
                        :format="'DD-MM-YYYY'"
                        :value-type="'YYYY-MM-DD'"
                        :class="validate[index].birth_date ? 'validate': ''"
                        @change="clearError"/>
                  </div>

                  <div class="form-group">
                    <label>Пол</label>
                    <div class="sex-group">
                      <div class="sex-item"
                           :class="[adults_[index].gender === 'M' ? 'active': '']"
                           @click="switchGender('M', index,'adults_')">
                        Мужской
                      </div>
                      <div class="sex-item"
                           :class="[adults_[index].gender === 'F' ? 'active': '']"
                           @click="switchGender('F', index, 'adults_')">
                        Женский
                      </div>
                    </div>
                  </div>
                </div>

                <div class="title mt-3">Гражданство</div>
                <div class="block">
                  <div class="form-group">
                    <label class="label">Тип документа</label>
                    <select v-model="adults_[index].citizen">
                      <option value="RU">Россия</option>
                    </select>
                  </div>
                </div>

                <div class="title mt-3">Документ</div>
                <div class="block">

                  <div class="form-group">
                    <label class="label">Тип документа</label>
                    <select v-model="adults_[index].doctype" @change="delete adults_[index].docexp">
                      <option value="C">Паспорт РФ</option>
                      <option value="P">Загранпаспорт РФ</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="label">Номер документа</label>
                    <template v-if="adults_[index].doctype === 'C'">
                      <masked-input type="text"
                                    class="masked"
                                    mask="11 11 111111"
                                    @input="adults_[index].docnum = arguments[1].replaceAll('_', '')"
                                    placeholder="Номер документа"
                                    :class="validate[index].docnum ? 'validate': ''"/>
                    </template>

                    <template v-else-if="adults_[index].doctype === 'P'">
                      <masked-input type="text"
                                    class="masked"
                                    mask="11 1111111"
                                    @input="adults_[index].docnum = arguments[1].replaceAll('_', '')"
                                    placeholder="Номер документа"
                                    :class="validate[index].docnum ? 'validate': ''"/>
                    </template>

                  </div>

                  <div class="form-group" v-if="adults_[index].doctype === 'P'">
                    <label class="label">Действителен до</label>
                    <date-picker
                        class="default"
                        v-model="adults_[index].docexp"
                        placeholder="Действителен до"
                        :format="'DD-MM-YYYY'"
                        :value-type="'YYYY-MM-DD'"/>
                  </div>

                </div>
              </div>
            </template>

            <template v-if="this.children_.length > 0">
              <div class="passenger" v-for="(adult, index) in children_" :key="adult.id">
                <div class="type">Ребенок</div>

                <div class="block">
                  <div class="form-group">
                    <label class="label">Фамилия</label>
                    <input
                        type="text" placeholder="Фамилия на латинице"
                        v-model="children_[index].last_name"
                        :class="validate2[index].last_name ? 'validate': ''"
                        v-transliteration/>
                  </div>
                  <div class="form-group">
                    <label class="label">Имя</label>
                    <input
                        type="text"
                        placeholder="Имя на латинице"
                        v-model="children_[index].first_name"
                        :class="validate2[index].first_name ? 'validate': ''"
                        v-transliteration/>
                  </div>
                  <div class="form-group">
                    <label class="label">Отчество</label>
                    <input
                        type="text"
                        placeholder="Отчество на латинице"
                        v-model="children_[index].middle_name"
                        :class="validate2[index].middle_name ? 'validate': ''"
                        v-transliteration/>
                    <div class="checkbox-custom">
                      <input
                          type="checkbox"
                          v-model="children_[index].notLastName">
                      <label>Нет отчества</label>
                    </div>
                  </div>
                </div>

                <div class="block">
                  <div class="form-group birthday">
                    <label class="label">Дата рождения</label>
                    <date-picker
                        class="default"
                        v-model="children_[index].birth_date"
                        placeholder="Дата рождения"
                        :format="'DD-MM-YYYY'"
                        :value-type="'YYYY-MM-DD'"
                        :class="validate2[index].birth_date ? 'validate': ''"/>
                  </div>

                  <div class="form-group">
                    <label>Пол</label>
                    <div class="sex-group">
                      <div class="sex-item"
                           :class="[children_[index].gender === 'M' ? 'active': '']"
                           @click="switchGender('M', index,'adults_')">
                        Мужской
                      </div>
                      <div class="sex-item"
                           :class="[children_[index].gender === 'F' ? 'active': '']"
                           @click="switchGender('F', index, 'adults_')">
                        Женский
                      </div>
                    </div>
                  </div>
                </div>

                <div class="title mt-3">Гражданство</div>
                <div class="block">
                  <div class="form-group">
                    <label class="label">Тип документа</label>
                    <select v-model="children_[index].citizen">
                      <option value="RU">Россия</option>
                    </select>
                  </div>
                </div>

                <div class="title mt-3">Документ</div>
                <div class="block">

                  <div class="form-group">
                    <label class="label">Тип документа</label>
                    <select v-model="children_[index].doctype">
                      <option value="C">Паспорт РФ</option>
                      <option value="P">Загранпаспорт РФ</option>
                      <option value="B">Свидетельство о рождении</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="label">Номер документа</label>
                    <template v-if="children_[index].doctype === 'C'">
                      <masked-input type="text"
                                    class="masked"
                                    mask="11 11 111111"
                                    @input="children_[index].docnum = arguments[1].replaceAll('_', '')"
                                    :class="validate2[index].docnum ? 'validate': ''"
                                    placeholder="Номер документа"/>
                    </template>

                    <template v-else-if="children_[index].doctype === 'P'">
                      <masked-input type="text"
                                    class="masked"
                                    mask="11 1111111"
                                    @input="children_[index].docnum = arguments[1].replaceAll('_', '')"
                                    :class="validate2[index].docnum ? 'validate': ''"
                                    placeholder="Номер документа"/>
                    </template>

                    <template v-else-if="children_[index].doctype === 'B'">
                      <input type="text"
                             v-model="children_[index].docnum"
                             :class="validate2[index].docnum ? 'validate': ''"
                             placeholder="IVЯЯ123456"/>
                    </template>

                  </div>

                  <div class="form-group" v-if="children_[index].doctype === 'P'">
                    <label class="label">Действителен до</label>
                    <date-picker
                        class="default"
                        v-model="children_[index].docexp"
                        placeholder="Действителен до"
                        :format="'DD-MM-YYYY'"
                        :value-type="'YYYY-MM-DD'"/>
                  </div>

                </div>
              </div>
            </template>

          </div>
        </div>

        <div class="panel">
          <div class="title">
            Контакты покупателя
          </div>
          <div class="block">
            <div class="form-group">
              <transition name="fade">
                <div class="email-validate" v-if="validate3.email">
                  Электронная почта указана неверно
                </div>
              </transition>
              <input type="text" placeholder="Электронная почта" v-model="customer.email"
                     :class="validate3.email ? 'validate': ''" @keyup="clearError">
            </div>
          </div>
        </div>

      </div>

      <div class="right">
        <div class="promo">
          <div class="title">Промокод</div>
          <div class="form-group">
            <input type="text" placeholder="Введите здесь" v-model="promocode.text"
                   :disabled="promocode.status">
            <template v-if="promocode.status">
              <button class="btn btn-success" @click="clearPromo">Отменить</button>
            </template>
            <template v-else>
              <button class="btn btn-success" @click="verifyPromo">Применить</button>
            </template>
          </div>
        </div>
        <div class="detail-confirmation">
          <div class="title">Детали заказа</div>
          <div class="price-amount">
            <div class="final">Итого:</div>
            <div class="price">
              {{ selectedTariff.amount }} руб.
            </div>
          </div>
          <button-load
              class="btn btn-success"
              :data-style="'zoom-in'"
              :loading="pLoading"
              @click="checkForm">Перейти к оплате
          </button-load>
          <span>Нажимая на кнопку, вы соглашаетесь с Условиями обработки перс. данных, а также c Условиями продажи для физ. лиц и юр. лиц</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import maskedInput from "vue-masked-input";
import * as EmailValidator from "email-validator";

export default {
  name: 'AviaConfirmation',
  components: {maskedInput},
  data: function () {
    return {
      item: [],
      tariffs: [],
      selectedTariff: {
        id: 0,
        amount: 0,
      },
      pLoading: false,
      data: {
        flightHash: '',
        from: {
          text: '',
          code: '',
        },
        to: {
          text: '',
          code: '',
        },
        date: this.$moment().add(1, 'days').format('Y-MM-DD'),
        returnDate: null,
        adults: 1,
        children: [],
      },
      promocode: {
        text: '',
        type: null,
        status: false,
        value: 0,
      },
      customer: {
        email: null,
      },
      adults_: [],
      children_: [],
      validate: [],
      validate2: [],
      validate3: {
        email: false,
      },
      form: false,
    }
  },
  computed: {
    getDate: function () {
      let returnDate = ''
      if (this.data.returnDate) {
        returnDate = ' - ' + this.$moment(this.data.returnDate).format('D MMM')
      }
      return this.$moment(this.data.date).format('D MMM') + ', ' + returnDate
    },
    getPassengers: function () {
      let children = '';
      if (this.data.children.length > 0) {
        children = ' и ' + this.data.children.length + ' '
            + this.$core.declination(this.data.children.length, ['ребенка', 'детей', 'детей'])
      }
      return this.data.adults + ' ' + this.$core.declination(this.data.adults, ['взрослый', 'взрослых', 'взрослых']) + children
    }
  },
  mounted() {
    if (Object.keys(this.$route.params).length <= 0) {
      this.$router.push({'name': 'index'})
    } else {
    this.data.from = this.$route.params.from
    this.data.to = this.$route.params.to
    this.data.date = this.$route.params.date
    this.data.returnDate = this.$route.params.returnDate
    this.data.adults = this.$route.params.adults
    this.data.children = this.$route.params.children
    this.item = this.$route.params.item
    this.selectedTariff.id = this.item.id
    this.selectedTariff.amount = this.item.amount
    this.setAdults()
    this.setChildren()
    this.getTariffs()
    }
  },
  methods: {
    checkForm: function () {
      this.clearError()
      if (!this.$core.checkAuth()) {
        this.$root.$emit('openModal', 'login')
      } else {
        const $this = this;
        this.form = false;

        this.adults_.map(function (value, key) {
          if (!value.first_name) {
            $this.form = true;
            $this.validate[key].first_name = true;
          }
          if (!value.last_name) {
            $this.form = true;
            $this.validate[key].last_name = true;
          }
          if (!value.middle_name) {
            $this.form = true;
            $this.validate[key].middle_name = true;
          }
          if (!value.birth_date) {
            $this.form = true;
            $this.validate[key].birth_date = true;
          }
          if (!value.docnum) {
            $this.form = true;
            $this.validate[key].docnum = true;
          }
        });

        if (this.children_.length > 0) {
          this.children_.map(function (value, key) {
            if (!value.first_name) {
              $this.form = true;
              $this.validate2[key].first_name = true;
            }
            if (!value.last_name) {
              $this.form = true;
              $this.validate2[key].last_name = true;
            }
            if (!value.middle_name) {
              $this.form = true;
              $this.validate2[key].middle_name = true;
            }
            if (!value.birth_date) {
              $this.form = true;
              $this.validate2[key].birth_date = true;
            }
            if (value.doctype !== 'B' && !value.docnum) {
              $this.form = true;
              $this.validate2[key].docnum = true;
            }
            if (value.doctype === 'B') {
              let regex = new RegExp('^M{0,3}(D?C{0,3}|C[DM])?(L?X{0,3}|X[LC])?(V?I{0,3}|I[VX])?[А-Я]{2}[0-9]{6}$');
              if (!regex.test(value.docnum)) {
                $this.form = true;
                $this.validate2[key].docnum = true;
              }
            }
          });
        }

        if (!EmailValidator.validate(this.customer.email)) {
          $this.form = true;
          this.validate3.email = true;
        }

        if (this.form) {
          this.$toast.error('Заполните все поля');
        } else {
          this.bookingVerify();
        }
      }
    },
    bookingVerify: function () {
      let passengers = this.adults_.concat(this.children_)

      let data = {
        email: this.customer.email,
        flight: {
          flight_hash: this.selectedTariff.id,
          date: this.data.date,
          passengers: passengers
        }
      }

      if (this.promocode.status) {
        data.flight = Object.assign({promocode: this.promocode.text}, data)
      }

      this.pLoading = true

      this.$store.dispatch('booking', data).then((res) => {
        if (res.data.data.amount && res.data.data.url) {
          window.location.href = res.data.data.url
        }
        this.pLoading = false
      }).catch((e) => {
        this.$toast.error(e.response.data.message)
      }).finally(() => {
        this.pLoading = false
      })
    },
    clearError: function () {
      const $this = this;
      this.adults_.map(function (value, key) {
        if (value.first_name) {
          $this.validate[key].first_name = false;
        }
        if (value.last_name) {
          $this.validate[key].last_name = false;
        }
        if (value.middle_name) {
          $this.validate[key].middle_name = false;
        }
        if (value.birth_date) {
          $this.validate[key].birth_date = false;
        }
        if (value.docnum) {
          $this.validate[key].docnum = false;
        }
      });

      if (this.children_.length > 0) {
        this.children_.map(function (value, key) {
          if (value.first_name) {
            $this.validate2[key].first_name = false;
          }
          if (value.last_name) {
            $this.validate2[key].last_name = false;
          }
          if (value.middle_name) {
            $this.validate2[key].middle_name = false;
          }
          if (value.birth_date) {
            $this.validate2[key].birth_date = false;
          }
          if (value.docnum) {
            $this.validate2[key].docnum = false;
          }
        });
      }


      if (this.customer.email) {
        this.validate3.email = false
      }
    },
    setAdults: function () {
      let count = parseInt(this.data.adults);
      if (this.adults_.length === 0) {
        for (let i = 1; i <= count; i++) {
          this.adults_.push({
            id: i,
            first_name: null,
            last_name: null,
            middle_name: null,
            notLastName: false,
            birth_date: '',
            gender: 'M',
            citizen: 'RU',
            doctype: 'C',
            docnum: '',
          });

          this.validate.push({
            id: i,
            last_name: false,
            first_name: false,
            middle_name: false,
            birth_date: false,
            docnum: false,
          });
        }
      }
    },
    setChildren: function () {
      let count = this.data.children.length;
      if (this.children_.length === 0) {
        for (let i = 1; i <= count; i++) {
          this.children_.push({
            id: i,
            first_name: null,
            last_name: null,
            middle_name: null,
            notLastName: false,
            birth_date: '',
            gender: 'M',
            citizen: 'RU',
            doctype: 'C',
            docnum: '',
          });

          this.validate2.push({
            id: i,
            last_name: false,
            first_name: false,
            middle_name: false,
            birth_date: false,
            docnum: false,
          });
        }
      }
    },
    clearPromo: function () {
      this.promocode.text = ''
      this.promocode.type = null
      this.promocode.value = 0
      this.promocode.status = false
      this.$toast.success('Промокод успешно отменён')
    },
    verifyPromo: function () {
      if (this.promocode.text <= 0) {
        this.$toast.warning('Укажите промокод')
      } else {
        this.$store.dispatch('verifyPromocode', this.promocode.text).then((res) => {
          if (res.data.result === 'ok') {
            this.promocode.text = res.data.data.code
            this.promocode.type = res.data.data.type
            this.promocode.value = res.data.data.value
            this.promocode.status = true
            this.$toast.success('Промокод успешно применен')
          }
        }).catch((e) => {
          this.$toast.error(e.response.data.message)
        })
      }
    },
    switchGender: function (val, i, type) {
      this[type][i].gender = val;
    },
    setTariff: function (tariff) {
      this.selectedTariff.id = tariff.id
      this.selectedTariff.amount = tariff.amount
    },
    getTariffs: function () {
      this.$store.dispatch('getTariffsAvia', this.item.id).then((res) => {
        this.tariffs = res.data.data
      })
    },
  }
}
</script>

<style scoped>
.avia-confirmation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 10px 50px 10px;
  position: relative;
}

.avia-confirmation .left {
  min-width: 800px;
  max-width: 1080px;
  width: 100%;
  margin-right: 30px;
}

.avia-confirmation .right {
  max-width: 450px;
  width: 100%;
}

.avia-confirmation .title {
  font-family: 'dinpro', serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.avia-confirmation .left .direction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.avia-confirmation .left .direction p {
  font-family: 'dinpro', serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}

.avia-confirmation .left .direction span {
  display: flex;
  align-items: center;
  margin: 0 15px 0 15px;
}

.avia-confirmation .left .date-and-persons {
  margin-top: 15px;
  font-family: 'dinpro', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.avia-confirmation .panel {
  background: rgba(231, 245, 251, .6);
  box-shadow: 0 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  padding: 15px 15px;
  margin-top: 30px;
}

.avia-confirmation .panel .title {
  font-family: 'dinpro', serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.avia-confirmation .panel .title-help {
  font-family: 'dinpro', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.avia-confirmation .panel .passenger {
  padding-bottom: 10px;
  border-bottom: 1px solid #96A3AE;
}

.avia-confirmation .panel .passenger:last-child {
  border-bottom: none;
}

.avia-confirmation .panel .passenger .type {
  font-family: 'dinpro', serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}

.avia-confirmation .panel .block {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}

.avia-confirmation .panel .block .form-group {
  width: 100%;
  max-width: 250px;
  margin-top: 10px;
}

.avia-confirmation .panel .block .form-group:not(:last-child) {
  margin-right: 15px;
}

.avia-confirmation .panel .block .form-group input {
  width: 100%;
  min-width: 250px;
  max-width: 250px;
  height: 45px;
}

.avia-confirmation .panel .block .form-group input[type=checkbox] {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.avia-confirmation .panel .block .form-group select {
  background: #FFFFFF;
  height: 45px;
  min-width: 250px;
  max-width: 250px;
}

.avia-confirmation .panel .block .form-group .checkbox-custom {
  margin-top: 5px;
}

.avia-confirmation .panel .block .form-group.birthday .date > {
  width: 100px;
}

.avia-confirmation .panel .block .form-group .email-validate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 65px;
  background: rgb(0, 0, 0, 1);
  color: var(--white);
  font-size: 12px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}

.avia-confirmation .panel .block .form-group .email-validate:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  width: 0;
  height: 0;
  border-top: solid 10px rgb(0, 0, 0, 1);
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.avia-confirmation .ticket {
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
  position: relative;
  width: 100%;
}

.avia-confirmation .ticket:not(:first-child) {
  margin-top: 20px;
}

.avia-confirmation .ticket .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
}

.avia-confirmation .ticket .logo img {
  width: 100px;
  height: 100px;
}

.avia-confirmation .ticket .logo span {
  max-width: 115px;
}

.avia-confirmation .ticket .timing {
  display: flex;
  flex-direction: column;
}

.avia-confirmation .ticket .timing .item {
  width: 100%;
}

.avia-confirmation .ticket .timing .item .there {
  display: flex;
  justify-content: space-between;
}

.avia-confirmation .ticket .timing .item .back {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.avia-confirmation .ticket .timing .item .datetime {
  display: flex;
  align-items: flex-start;
}

.avia-confirmation .ticket .timing .item .datetime time {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.avia-confirmation .ticket .timing .item .datetime span {
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: #FF5252;
  margin-left: 7px;
}

.avia-confirmation .ticket .timing .item .airport {
  max-width: 135px;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}

.avia-confirmation .ticket .timing .item .in-flight {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  width: 270px;
}

.avia-confirmation .ticket .timing .item .in-flight .line-flight {
  border-bottom: 1px solid #EEEEEF;
  width: 80px;
  height: 13px;
  margin-left: 5px;
  margin-right: 5px;
}

.avia-confirmation .ticket .timing .item .in-flight span {
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  width: 125px;
}

.avia-confirmation .tariffs {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  padding-bottom: 10px;
  max-width: 1000px;
  overflow-x: auto;
}

.avia-confirmation .tariffs .tariff {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  border: 1px solid #EDEEF3;
  border-radius: 13px;
  padding: 20px 20px;
  min-width: 200px;
}

.avia-confirmation .tariffs .tariff:not(:last-child) {
  margin-right: 20px;
}

.avia-confirmation .tariffs .tariff.active {
  background: #F7F7F8;
  border: 1px solid #43B02A;
}

.avia-confirmation .tariffs .tariff .name {
  font-family: 'dinpro', serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 3px;
}

.avia-confirmation .tariffs .tariff .price {
  font-family: 'dinpro', serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 5px;
}

.avia-confirmation .tariffs .tariff .additions li {
  position: relative;
  padding-left: 20px;
  font-family: 'dinpro', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.avia-confirmation .tariffs .tariff .additions li:not(:last-child) {
  margin-bottom: 5px;
}

.avia-confirmation .tariffs .tariff .additions li.mark:before {
  content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.46681 12C4.07549 12 3.70539 11.8255 3.46747 11.5262L0.254191 7.48094C-0.163744 6.95482 -0.0549441 6.20567 0.496968 5.8071C1.04978 5.40835 1.83512 5.51275 2.25323 6.03853L4.36665 8.69878L9.68201 0.563063C10.048 0.0033448 10.8207 -0.167914 11.4091 0.180432C11.9968 0.52912 12.1763 1.26627 11.8102 1.8265L5.53107 11.4369C5.31293 11.771 4.93636 11.9811 4.524 11.999C4.50476 11.9997 4.48588 12 4.46681 12Z' fill='%2343B02A'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
}

.avia-confirmation .tariffs .tariff .additions li.pay:before {
  content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8.33301C5 9.43767 6.79067 10.333 9 10.333C11.2093 10.333 13 9.43767 13 8.33301C13 7.22834 11.2093 6.33301 9 6.33301C6.79067 6.33301 5 7.22834 5 8.33301Z' stroke='%23FF5252' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 8.33333V11C5 12.104 6.79067 13 9 13C11.2093 13 13 12.104 13 11V8.33333M1 3C1 3.71467 1.76267 4.37467 3 4.732C4.23733 5.08933 5.76267 5.08933 7 4.732C8.23733 4.37467 9 3.71467 9 3C9 2.28533 8.23733 1.62533 7 1.268C5.76267 0.910667 4.23733 0.910667 3 1.268C1.76267 1.62533 1 2.28533 1 3Z' stroke='%23FF5252' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 3V9.66667C1 10.2587 1.51467 10.6333 2.33333 11' stroke='%23FF5252' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 6.33301C1 6.92501 1.51467 7.29967 2.33333 7.66634' stroke='%23FF5252' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
}

.avia-confirmation .tariffs .tariff .additions li.cross:before {
  content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.40864 5.99915L11.7045 1.71268C11.8926 1.52453 11.9983 1.26935 11.9983 1.00326C11.9983 0.737183 11.8926 0.481998 11.7045 0.29385C11.5164 0.105701 11.2612 0 10.9952 0C10.7291 0 10.474 0.105701 10.2859 0.29385L6 4.59031L1.71414 0.29385C1.52602 0.105701 1.27087 -1.98247e-09 1.00483 0C0.738783 1.98247e-09 0.483635 0.105701 0.295513 0.29385C0.107391 0.481998 0.00170495 0.737183 0.00170495 1.00326C0.00170495 1.26935 0.107391 1.52453 0.295513 1.71268L4.59136 5.99915L0.295513 10.2856C0.201875 10.3785 0.127553 10.489 0.0768329 10.6108C0.0261132 10.7325 0 10.8631 0 10.995C0 11.1269 0.0261132 11.2575 0.0768329 11.3793C0.127553 11.501 0.201875 11.6116 0.295513 11.7044C0.388386 11.7981 0.49888 11.8724 0.620622 11.9232C0.742363 11.9739 0.872943 12 1.00483 12C1.13671 12 1.26729 11.9739 1.38903 11.9232C1.51077 11.8724 1.62127 11.7981 1.71414 11.7044L6 7.40799L10.2859 11.7044C10.3787 11.7981 10.4892 11.8724 10.611 11.9232C10.7327 11.9739 10.8633 12 10.9952 12C11.1271 12 11.2576 11.9739 11.3794 11.9232C11.5011 11.8724 11.6116 11.7981 11.7045 11.7044C11.7981 11.6116 11.8724 11.501 11.9232 11.3793C11.9739 11.2575 12 11.1269 12 10.995C12 10.8631 11.9739 10.7325 11.9232 10.6108C11.8724 10.489 11.7981 10.3785 11.7045 10.2856L7.40864 5.99915Z' fill='%23FF5252'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
}

.avia-confirmation .tariffs .tariff .btn {
  width: 90px;
  height: 30px;
  background: #EDEEF3;
  margin-top: 15px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .avia-confirmation {
    flex-direction: column;
  }

  .avia-confirmation .right {
    max-width: 100%;
    min-width: initial;
  }

  .avia-confirmation .ticket {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .avia-confirmation .panel .title-help {
    font-size: 14px;
  }

  .avia-confirmation .ticket {
    padding: 10px 10px;
  }
}

@media screen and (max-width: 576px) {
  .avia-confirmation .panel .block .form-group {
    width: 100%;
    max-width: 100%;
    margin-right: 0 !important;
  }

  .avia-confirmation .panel .block .form-group input {
    width: 100%;
    max-width: 100%;
  }

  .avia-confirmation .panel .block .form-group select {
    width: 100%;
    max-width: 100%;
  }

  .avia-confirmation .ticket .timing .item .datetime time {
    font-size: 20px;
  }

  .avia-confirmation .ticket .timing .item .datetime span {
    font-size: 16px;
  }
}


</style>

